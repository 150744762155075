<template>
  <content-items-edit
    content-type="playlists"
    content-type-singular="playlist"
    content-name="Playlists"
    content-name-singular="Playlist"
  />
</template>

<script>
import ContentItemsEdit from '@/views/content-item/content-items-edit/ContentItemsEdit.vue'

export default {
  components: {
    ContentItemsEdit,
  },
}
</script>

<style scoped>

</style>
